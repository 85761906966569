.App {
  text-align: center;
  padding: 20px;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.card {
  width: calc(33.33% - 20px); /* Three cards per row, considering the gap */
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #fff; /* White background for the card */
  display: flex;
  flex-direction: column;
}

.card-image {
  height: 200px; /* Increased height for better image visibility */
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center;
  background-repeat: no-repeat;
}

.card-header {
  padding: 10px;
  background-color: #f8f8f8; /* Light grey background for the header */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-header h2 {
  margin: 0;
  font-size: 1.5em;
  color: #333; /* Dark text color */
  text-align: center; /* Center-align text */
}

.card-meaning {
  padding: 10px;
  background-color: #f5f5f5; /* Light grey background for meaning */
  color: #666; /* Slightly darker text for meaning */
  flex-grow: 1; /* Pushes the meaning section to fill available space */
}

.tag {
  display: inline-block;
  background-color: #4CAF50; /* Green background for the tag */
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9em;
}
